import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Page from 'src/components/pages/login';
import { WEB_APP } from 'src/static/app';

const Container: NextPage = (props) => {
  return (
    <>
      <NextSeo title={`ログイン | ${WEB_APP.name.default}`} />
      <Page {...props} />
    </>
  );
};

export default Container;
