import React, { FC } from 'react';

import { Loading } from 'src/components/ui/loading';
import { AuthGateway } from 'src/components/functional/auth/auth-gateway';
import Image from 'next/image';

import Form from './form';

const Component: FC = () => {
  return (
    <div className='min-h-[80vh] mx-auto flex flex-col-reverse lg:flex-row items-stretch'>
      <div className='flex-[0.66] bg-slate-100 flex items-center justify-center'>
        <Image src='/img/hungry-blue.webp' width={400} height={400} alt={'ログイン'} />
      </div>
      <div className='flex-1 bg-white py-6 sm:py-8 lg:py-12 flex justify-center items-center'>
        <Form />
      </div>
    </div>
  );
};

const Container: FC = () => {
  return (
    <AuthGateway
      ifNotAuth={{
        component: <Component />,
      }}
      ifRegistered={{
        path: '/account',
        component: (
          <div className='h-[80vh]'>
            <Loading label='管理画面へ移動します' className='h-4/5' />
          </div>
        ),
      }}
    />
  );
};

export default Container;
