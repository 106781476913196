import React, { ChangeEventHandler, FC, useState } from 'react';
import Link from 'next/link';

import ContinueWithGoogle from 'src/components/functional/auth/continue-with-google';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from 'src/lib/firebase/firebase';
import { FirebaseError } from 'firebase/app';
import { ErrorMessage } from 'src/components/ui/error-message';
import { AppTextField } from 'src/components/ui/app-text-field';
import { AppButton } from 'src/components/ui/app-button';

type ErrorMessage = {
  title: string;
  description: string;
};

const Component: FC = () => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [mailError, setMailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const onMailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMail(e.target.value);
  };

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
  };

  const onSubmitButtonClick = async () => {
    setErrorMessage(null);
    setMailError(null);
    setPasswordError(null);
    let causesError = false;

    if (!mail) {
      setMailError('この項目は入力が必須です');
      causesError = true;
    }

    if (!password) {
      setPasswordError('この項目は入力が必須です');
      causesError = true;
    }

    if (causesError) {
      return;
    }

    try {
      await signInWithEmailAndPassword(firebaseAuth, mail, password);
    } catch (error: any) {
      if (!(error instanceof FirebaseError)) {
        setErrorMessage({
          title: 'アカウントが存在しません',
          description:
            '入力されたメールアドレスを含むアカウント情報が見つかりませんでした。メールアドレスに誤りがないことを確認し、もう一度入力をお試しください。',
        });
        return;
      }

      switch (error.code) {
        case 'auth/wrong-password':
          setErrorMessage({
            title: 'もう一度入力をお試しください',
            description:
              'パスワードが間違っているようです。Caps Lockがオンになっていないことを確認し、もう一度入力をお試しください。',
          });
          break;
        case 'auth/user-not-found':
          setErrorMessage({
            title: 'アカウントが存在しません',
            description:
              '入力されたメールアドレスを含むアカウント情報が見つかりませんでした。メールアドレスに誤りがないことを確認し、もう一度入力をお試しください。',
          });
          break;
        default:
          setErrorMessage({
            title: 'アカウントが存在しません',
            description:
              '入力されたメールアドレスを含むアカウント情報が見つかりませんでした。メールアドレスに誤りがないことを確認し、もう一度入力をお試しください。',
          });
      }

      if (process.env.NODE_ENV === 'development') {
        console.error({ error });
      }
    }
  };

  return (
    <div className='py-6 sm:py-8 lg:py-12'>
      <div className='max-w-screen-2xl px-0 md:px-8 mx-auto'>
        <h2 className='text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8'>
          ログイン
        </h2>

        {!!errorMessage && (
          <ErrorMessage title={errorMessage.title} text={errorMessage.description} />
        )}

        <div className='bg-white max-w-lg mx-auto'>
          <div className='flex flex-col gap-4 p-4 md:p-8'>
            <AppTextField
              name='email'
              type='email'
              label='メールアドレス'
              value={mail}
              onChange={onMailChange}
              errorMessage={mailError || undefined}
              error={!!mailError}
            />
            <AppTextField
              name='password'
              type='password'
              label='パスワード'
              value={password}
              onChange={onPasswordChange}
              errorMessage={passwordError || undefined}
              error={!!passwordError}
            />
            <AppButton onClick={onSubmitButtonClick}>ログイン</AppButton>

            <div className='flex justify-center items-center relative'>
              <span className='h-px bg-gray-300 absolute inset-x-0'></span>
              <span className='bg-white text-gray-400 text-sm relative px-4'>または</span>
            </div>
            <ContinueWithGoogle action='ログイン' />
          </div>

          <div className='flex justify-center items-center p-4'>
            <small className='text-gray-500'>
              <Link href='/terms'>
                <a className='text-indigo-500'>利用規約</a>
              </Link>
              、
              <Link href='/privacy'>
                <a className='text-indigo-500'>プライバシーポリシー</a>
              </Link>
              に同意した上で実行してください。
            </small>
          </div>
        </div>
        <div className='flex justify-center items-center mt-8 p-4'>
          <p className='text-gray-500 text-center'>
            アカウントをお持ちでない場合
            <Link href='/signup'>
              <a className='text-indigo-500 hover:text-indigo-600 active:text-indigo-700 transition duration-100'>
                サインアップ
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Component;
