import { atomFamily, RecoilState } from 'recoil';
import { getDataFromPath } from 'src/lib/firebase/database';

export const cachedDataState = atomFamily<any, string>({
  key: 'cachedDataState',
  default: (path: string) => {
    return getDataFromPath(path);
  },
  effects: [
    ({ onSet }) =>
      onSet((newValue, oldValue) =>
        console.log(`[🐘observer] atom family changed: ${oldValue} → ${newValue}`)
      ),
  ],
});

export const getAppsState = (uid: string) =>
  cachedDataState(`apps/${uid}`) as RecoilState<rtdb.Apps | null>;
