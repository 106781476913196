import { useRouter } from 'next/router';
import React, { FC, Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { DeepReadonly } from 'utility-types';
import { Loading } from '../../ui/loading';
import { cachedDataState } from '../../../states/rtdb';
import { useAuthContext } from 'src/providers/auth';

type Props = DeepReadonly<{
  ifNotAuth: {
    path?: string;
    component: JSX.Element;
  };
  ifRegistered: {
    path?: string;
    component: JSX.Element;
  };
}>;

const AuthContainer: FC<Props> = (props) => {
  const router = useRouter();
  const { uid, loading: authLoading } = useAuthContext();

  if (authLoading) {
    return (
      <div className='h-[80vh]'>
        <Loading label='認証状態を確認しています' className='h-4/5' />
      </div>
    );
  }

  if (!uid) {
    if (props.ifNotAuth.path) {
      router.replace(props.ifNotAuth.path);
    }
    return props.ifNotAuth.component;
  }

  return (
    <Suspense
      fallback={
        <div className='h-[80vh]'>
          <Loading label='ユーザー情報を取得しています' className='h-4/5' />
        </div>
      }
    >
      <DatabaseContainer {...props} />
    </Suspense>
  );
};

const DatabaseContainer: FC<Props> = (props) => {
  const router = useRouter();
  const { uid } = useAuthContext();
  const registrationStep = useRecoilValue(cachedDataState(`registrationStep/${uid}`));

  if (!registrationStep || registrationStep === '0') {
    router.replace('/onboarding');
    return (
      <div className='h-[80vh]'>
        <Loading
          label='基本情報の登録が完了していないため、基本情報登録画面へ移動します'
          className='h-4/5'
        />
      </div>
    );
  }

  if (props.ifRegistered.path) {
    router.replace(props.ifRegistered.path);
  }
  return props.ifRegistered.component;
};

export const AuthGateway = AuthContainer;
