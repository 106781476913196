import React, { FC } from 'react';
import { DeepReadonly } from 'utility-types';

type ContainerProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  DeepReadonly<{ label: string; name: string; error?: boolean; errorMessage?: string }>;
type Props = ContainerProps & DeepReadonly<{}>;

const Component: FC<Props> = ({
  className,
  label,
  name,
  error = false,
  errorMessage = '入力値に誤りがあります',
  ...others
}) => (
  <div>
    <label htmlFor={name} className='inline-block text-gray-800 text-sm sm:text-base mb-2'>
      {label}
    </label>
    <input
      {...others}
      name={name}
      className={`w-full bg-gray-50 text-gray-800 border rounded outline-none transition duration-100 px-3 py-2 ${
        error ? 'border-red-500' : 'focus:ring ring-indigo-300'
      }`}
    />
    {error && <p className='text-red-500 text-xs'>{errorMessage}</p>}
  </div>
);

export const AppTextField = Component;
