import React, { FC } from 'react';
import { DeepReadonly } from 'utility-types';

type Props = DeepReadonly<{ label: string; className?: string }>;

const Component: FC<Props> = ({ className, label }) => (
  <div className={`flex flex-col justify-center items-center gap-8 py-36 ${className}`}>
    <div className='flex justify-center my-12 space-x-3'>
      {new Array(3).fill('').map((_, i) => (
        <div key={i} className='animate-ping  h-2 w-2 bg-blue-600 rounded-full'></div>
      ))}
    </div>
    <p className='text-gray-600'>{label}</p>
  </div>
);

export const Loading = Component;
