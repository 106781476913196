import { get, getDatabase, ref, update } from 'firebase/database';
import { kintone } from 'src/types/kintone';
import { firebaseAuth } from './firebase';
import './initialize-firebase';

export const writeGeneralSetting = (subdomain: string, name: string) => {
  const db = getDatabase();
  const uid = firebaseAuth.currentUser?.uid;

  if (!uid) {
    throw 'ユーザー情報が取得できませんでした';
  }

  const updates = {
    [`registrationStep/${uid}`]: '1',
    [`subdomain/${uid}`]: subdomain,
    [`userName/${uid}`]: name,
  };

  return update(ref(db), updates);
};

export const addKintoneApp = (
  uid: string,
  appId: string,
  apiToken: string,
  appInfo: {
    app?: kintone.App;
    fields?: Record<string, kintone.Field>;
  }
) => {
  const db = getDatabase();

  const updates = {
    [`apps/${uid}/${appId}`]: { appId, apiToken, appName: appInfo.app?.name || '' },
  };

  return update(ref(db), updates);
};

export const removeKintoneApp = (appId: string) => {
  const db = getDatabase();
  const uid = firebaseAuth.currentUser?.uid;

  if (!uid) {
    throw 'ユーザー情報が取得できませんでした';
  }

  return update(ref(db, `apps/${uid}`), { [appId]: null });
};

export const updateAppConfig = (appId: string, newParams: Partial<rtdb.App>) => {
  const db = getDatabase();
  const uid = firebaseAuth.currentUser?.uid;

  console.log({ newParams });

  if (!uid) {
    throw 'ユーザー情報が取得できませんでした';
  }

  const updates = Object.entries(newParams).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`apps/${uid}/${appId}/${key}`]: value,
    };
  }, {});

  console.log({ updates });

  return update(ref(db), updates);
};

export const getDataFromPath = async (path: string) => {
  const db = getDatabase();
  const snapshot = await get(ref(db, path));
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.val();
};

/** 👍 対象ユーザーのサブドメインをデータベースから取得し返却します */
export const getSubdomain = async (uid: string): Promise<string | null> => {
  return getDataFromPath(`subdomain/${uid}`);
};

/** 👍 対象ユーザーの登録状態をデータベースから取得し返却します */
export const getRegistrationStep = async (uid: string): Promise<string | null> => {
  return getDataFromPath(`registrationStep/${uid}`);
};

export const getUserApp = async (uid: string, appId: string): Promise<rtdb.App | null> => {
  return getDataFromPath(`apps/${uid}/${appId}`);
};

export const getAllSubdomains = async (): Promise<rtdb.SubDomains> => {
  return getDataFromPath('subdomain');
};

export const getAllUserApps = async (): Promise<Record<string, rtdb.Apps>> => {
  return getDataFromPath('apps');
};
